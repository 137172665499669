var _gaq = _gaq || []
_gaq.push(['_setAccount', 'UA-90003075-1'])
_gaq.push(['_trackPageview']);

(function () {
  var ga = document.createElement('script')
  ga.type = 'text/javascript'
  ga.async = true
  ga.src = (
    document.location.protocol === 'https:'
      ? 'https://ssl'
      : 'http://www'
    ) + '.google-analytics.com/ga.js'

  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(ga, s)
})()
